// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";

body {
  font-family: 'Inter', sans-serif;
  color: #4F4B5C;
}

.footer {
  background: #110C22;
  margin-top: 80px;
}

.rounded-right-0 {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.btn-primary {
  color: #fff;
  &:hover {
    color: #fff;
  }
}

.btn-mhtc {
  width: 220px;
  margin-bottom: 5px;
  border-color: #fff;
  background: #03275a;
}

.btn-mhtc-j {
  width: 220px;
  margin-bottom: 5px;
}

.input-group {
  .input-group-text {
    background-color: transparent;
  }
  .form-control,
  .form-select {
    &:focus {
      box-shadow: none;
    }
  }
  &:focus-within {
    border-radius: 0.375rem;
    box-shadow: 0 0 0 0.25rem rgba(63, 172, 86, 0.25);
    .input-group-text {
      border-color: var(--cui-input-focus-border-color, #9fd6ab);
    }
  }

  &.is-invalid {
    margin-bottom: 0;
    .input-group-text {
      border-color: #dc3545;
    }
    &:focus-within {
      box-shadow: 0 0 0 0.25rem rgba(220,53,69,.25);
    }
  }
}

.form-control,
html:not([dir=rtl]) .form-select {
  padding: 12px 16px;
  line-height: 1.25;
}

.toast {
  position: fixed;
  z-index: 1090;
  background: #FFFFFF;
  top: 136px;
  right: 48px;
  border: 1px solid #C6F1DD;
  box-shadow: 0px 16px 20px -8px rgba(17, 12, 34, 0.1);
  border-radius: 12px;
  &.danger {
    border-color: rgb(237 83 83);
  }
  .toast-body {
    display: flex;
    align-items: center;
    gap: 8px;
    span {
      font-weight: 600;
      font-size: 16px;
    }
  }
}

.flex-1 {
  flex: 1;
}

.gap-24 {
  gap: 24px;
}

.link-no-underline {
  text-decoration: none;
}

.color-inherit {
  color: inherit;
}

.loading-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 99999;
  background: rgb(255 255 255 / 50%);
}

.mt-48 {
  margin-top: 48px;
}

.cursor-pointer {
  cursor: pointer;
}

.react-datepicker__month-text {
  &[aria-label*="Not available"] {
    color: #ccc;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.is-invalid {
  .ck-editor {
    border: 1px solid #e55353 !important;
  }
}

.ck-content {
  .image-inline {
    picture {
      max-height: 500px;
    }
  }
}

blockquote {
  border-left: 5px solid #ccc;
  font-style: italic;
  margin-left: 0;
  margin-right: 0;
  overflow: hidden;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.form-control-select {
  &>div {
    &:nth-child(3) {
      border: 1px solid var(--cui-input-border-color, #b1b7c1);
      height: 46px;
      // box-shadow: 0 0 0 1px  var(--cui-input-focus-border-color, #9fd6ab);
      &:hover {
        border-color: var(--cui-input-focus-border-color, #9fd6ab);
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba(63, 172, 86, 0.25);
      }
    }
  }
}

.react-datepicker__view-calendar-icon {
  input {
    padding: 12px 16px !important;
  }
}

@media (max-width: 576px) {
  .toast {
    top: 136px;
    right: 8px;
  }
}
